<template>
    <div id="index">
        <div class="content_top flex flex-child-center ">
            <div style="width: 65%" class="content_top_box">
                <div style="width: 13%;padding-left: 0.27rem;">配件</div>
                <div style="width: 7%;">商品图片</div>
                <div style="width: 50%;text-align: center;">商品名称</div>
                <div class="text-center" style="width: 12%">数量</div>
                <div class="text-center" style="width: 12%">价格</div>
                <div class="text-center" style="width: 12%">小计</div>
                <!--                <div class="text-center" style="width: 5%"></div>-->
            </div>
            <div style="width: 34%" class="content_top_box">
                <div class="text-center" style="width: 50%;cursor: pointer;" @click="recommend">推荐配置单</div>
                <div style="width: 0.01rem;height: 0.18rem;border: 0.01rem solid #979797;"></div>
                <div class="text-center" style="width: 50%;cursor: pointer;" @click="my_template">我的模板</div>
            </div>
        </div>
        <div class="content_body flex flex-justify-content-between">
            <div style="width: 65%" class="content_body_left flex">
                <div style="width: 12%;">
                    <div v-for="(item, index) in accessoriesTypeList" :key="index" class="accessoriesBox" @click="accessorClick(item)">
                        <div>{{ item.name }}</div>
                        <div><img src="../assets/images/whiteJT.png"></div>
                    </div>
                    <div class="accessoriesBoxBottom">
                        <div v-if="$store.state.user.token && $store.state.user.is_vip"><span></span><span></span></div>
                        <div v-else><span></span><span></span></div>
                    </div>
                </div>
                <div style="width: 88%;">
                    <div class="flex flex-child-center" v-for="(item, index) in shopList" :key="index" style="margin-bottom: 0.05rem;height: 0.56rem;justify-content: space-between;">
                        <div style="width: 7%;padding: 0 0.15rem" class="shop_box flex flex-child-center">
                            <el-image v-if="item.goods_id" :src="item.goods_image" style="cursor: pointer;height: 0.5rem;" fit="contain" :preview-src-list="[item.goods_image]">
                            </el-image>
                            <img v-else src="../assets/images/moreImage.png" style="cursor: pointer;height: 0.5rem;width: 55px;" fit="contain">
                        </div>
                        <div style="width: 54%;" class="shop_box flex flex-child-center">
                            <el-select v-model="item.goods_id" filterable remote reserve-keyword placeholder="" @change="selectGoods(item, index)" :loading="loading" style="width: 100%">
                                <el-option v-for="goods in item.dialogList" :key="goods.id" :label="goods.goods_name" :value="goods.id" />
                            </el-select>
                        </div>
                        <!-- <div style="width: 52%;" class="shop_box flex flex-child-center">
                            <el-input type="text" v-model="item.goods_name" @change="goodInput(item, index)" @focus="goodfocus(item, index)"></el-input>
                        </div> -->
                        <div style="width: 12%;" class="flex flex-child-center num_input">
                            <el-button class="btn_minute" @click="btnMinute(item, index)">-</el-button>
                            <div class="input">
                                {{ item.goods_num }}
                            </div>
                            <el-button class="btn_add" @click="btnAdd(item, index)">+</el-button>
                        </div>
                        <div v-if="$store.state.user.token && $store.state.user.is_vip" class="price_input" style="width: 12%;background: #FFFFFF;border-radius: 0.02rem;line-height: 0;font-size: 0.16rem;font-weight: 500;color: #999999;">
                            ￥<el-input type="number" v-model="item.price" @change="goodInputNum(item, index)" @focus="goodfocusNum(item, index)"></el-input>
                        </div>
                        <div v-else class="price_input" style="width: 12%;background: #FFFFFF;border-radius: 0.02rem;line-height: 0;font-size: 0.16rem;font-weight: 500;color: #999999;">
                            会员可查看价格
                        </div>
                        <div v-if="$store.state.user.token && $store.state.user.is_vip" class="price_input" style="width: 12%;background: #FFFFFF;border-radius: 0.02rem;line-height: 0;font-size: 0.16rem;font-weight: 500;color: #999999;">
                            ￥<el-input type="number" v-model="item.total_price"></el-input>
                        </div>
                        <div v-else class="price_input" style="width: 12%;background: #FFFFFF;border-radius: 0.02rem;line-height: 0;font-size: 0.16rem;font-weight: 500;color: #999999;">
                            会员可查看价格
                        </div>
                        <!--                       <div style="width: 6%">-->
                        <!--                           <div class="delete" style="margin-left: 0.1rem;cursor: pointer;" @click="deleteShop(item,index)">-->
                        <!--                               <img src="../assets/images/shopDelete.png" class="shopDelete">-->
                        <!--                           </div>-->
                        <!--                       </div>-->
                    </div>
                    <div class="accessoriesBoxBottom">
                        <div v-if="$store.state.user.token && $store.state.user.is_vip"><span>未税总价</span> <span>¥{{ totalPrice }}</span></div>
                        <div v-else><span>未税总价</span> <span>会员可查看价格</span></div>
                    </div>
                </div>
            </div>
            <div style="width: 34%" class="content_body_right">
                <div class="templateBox" v-if="templateList.length > 0">
                    <div v-for="(item, index) in templateList" :key="index">
                        <div class="flex flex-child-center">
                            <el-radio v-model="template_id" :label="item.id" @input="radioInput" style="width: 85%;">
                                <div class="content-main-box">
                                    <!--                                    <div class="content-main-box-image">-->
                                    <!--                                        <img v-if="item.goods.goods_image" :src="item.goods.goods_image">-->
                                    <!--                                    </div>-->
                                    <div>
                                        <div class="content-main-box-text">
                                            {{ item.name }}
                                        </div>
                                        <div class="content-main-box-spec">
                                            备注:<span>{{ item.remark }}</span>
                                        </div>
                                        <!--                                        <div  class="content-main-box-spec">-->
                                        <!--                                            -->
                                        <!--                                        </div>-->
                                        <div class="content-main-box-price flex">
                                            <span v-if="$store.state.user.token && $store.state.user.is_vip">￥{{ item.total_price }}</span>
                                            <span v-else style="font-size: 0.15rem;width: 1.2rem;">会员可查看价格</span>
                                            <span style="font-size: 0.12rem;color: #666666;font-weight: 400;">数量：{{ item.total_num }}</span>
                                        </div>
                                    </div>
                                </div>
                            </el-radio>
                            <el-button class="delete" type="danger" v-if="item.user_id" @click="deleteTemplate(item, index)">删除</el-button>
                        </div>

                    </div>
                </div>
                <div class="templateBox" v-else>
                    <el-empty :image="require('../assets/images/null.png')" description="暂无数据"></el-empty>
                </div>
                <div class="flex flex-justify-content-between flex-child-center priceBox">
                    <div v-if="$store.state.user.token && $store.state.user.is_vip"><span></span><span></span></div>
                    <div v-else><span></span><span></span></div>
                    <div v-if="$store.state.user.token && $store.state.user.is_vip" @click="jiesuan">结算</div>
                    <div v-else><span>会员可结算</span></div>
                </div>
                <div class="content_bottom flex flex-child-center flex-justify-content-between" style="margin-bottom: 0.1rem;">
                    <el-input type="text" v-model="name" placeholder="请输入模板名称"></el-input>
                </div>
                <div class="content_bottom flex flex-child-center flex-justify-content-between">
                    <el-input type="textarea" v-model="remark" placeholder="请输入模板备注"></el-input>
                </div>
                <div class="flex flex-child-center flex-justify-content-between button_box">
                    <div v-if="$store.state.user.token && $store.state.user.is_vip" @click="printShow">打印</div>
                    <div v-else><span>会员可打印</span></div>
                    <div v-if="$store.state.user.token && $store.state.user.is_vip" @click="derive">
                        导出配置单
                    </div>
                    <div v-else><span>会员可导出</span></div>
                    <div @click="qingkong">清空</div>
                    <div @click="submitTem">保存到模版</div>
                </div>
            </div>
        </div>
        <div class="bottom_text">{{ $store.state.text }}</div>
        <!--弹出-->
        <el-dialog custom-class="dialog" :visible.sync="dialogFormVisible" :destroy-on-close="true" ref="dialog" @close="onDialogClose" :close-on-click-modal="false" width="50vw" top="15vh">
            <div class="dialog_con">
                <!--间值设置-->
                <div class=" tan_body flex  flex-wrap" v-if="dialogList.length > 0">
                    <div v-for="(item, index) in dialogList" :key="index" style="width: 50%;">
                        <div>
                            <el-radio v-model="template_id" :label="item.id">
                                <div class="content-main-box">
                                    <div class="content-main-box-image" style="width: 50px;height: 50px;text-align: center">
                                        <el-image v-if="item.goods_image" :src="item.goods_image" style="cursor: pointer;height: 50px;" fit="contain" :preview-src-list="[item.goods_image]">
                                        </el-image>
                                        <img v-else src="../assets/images/moreImage.png">
                                    </div>
                                    <div style="margin-left: 0.1rem;">
                                        <div class="content-main-box-text">
                                            {{ item.goods_name }}
                                        </div>
                                        <div class="content-main-box-spec">
                                            {{ item.goods_spec }}
                                        </div>
                                        <div class="content-main-box-price">
                                            <!-- <span v-if="$store.state.turnOnOff">￥{{item.price_profit}}</span> -->
                                            <span v-if="$store.state.user.token && $store.state.user.is_vip">￥{{ $store.state.turnOnOff ? item.price_profit : item.price }}</span>
                                            <span v-else style="font-size: 0.15rem;width: 1.5rem;">会员可查看价格</span>
                                        </div>
                                    </div>
                                </div>
                            </el-radio>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <el-empty :image="require('../assets/images/null.png')" description="暂无商品"></el-empty>
                </div>
            </div>
            <div slot="footer" class="dialog-footer padding-10 no-padding-left no-padding-right no-padding-top">
                <el-button type="primary" round @click="buttonSubmit">确定</el-button>
            </div>
        </el-dialog>
        <!--弹出-->
        <el-dialog custom-class="dialog" :visible.sync="printVisible" ref="dialog" width="70vw" top="10vh" style="height: 800px;" class="printBox">
            <div class="dialog_con_print">
                <div class="flex-justify-content-between flex">
                    <div></div>
                    <div>
                        <el-button type="primary" v-print="printObj" style="background-color: #4B83F0; border-color: #4B83F0;">
                            打印
                        </el-button>
                        <el-button type="primary" @click="printPreview" style="background-color: #CF7AE2; border-color: #CF7AE2;">
                            保存为图片
                        </el-button>
                        <el-button type="primary" @click="onDialogClose" style="background: #FFFFFF; border-color: #4B83F0;color: #333333">
                            关闭
                        </el-button>
                    </div>
                </div>
                <div ref="print" id="print" class="print-html">
                    <div class="text-center" style="font-size: 36px;font-weight: 500;">装机配置单</div>
                    <div class="text-center" style="font-size: 20px;font-weight: 400;margin-top: 10px;">生成日期 {{ recharge_time }}</div>
                    <!--                   &lt;!&ndash;间值设置&ndash;&gt;-->
                    <!--                   <el-table-->
                    <!--                       style="width: 100%"-->
                    <!--                       class=""-->
                    <!--                       stripe-->
                    <!--                       ref="IndexTable"-->
                    <!--                       :data="printList"-->
                    <!--                   >-->
                    <!--                       <el-table-column v-for="item in tableTitle" :key="item.name" :prop="item.value" :label="item.name"-->
                    <!--                                        :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">-->
                    <!--                       </el-table-column>-->
                    <!--                   </el-table>-->
                    <div style="border: 1px solid #F0F0F0;margin-top: 30px;">
                        <div class="flex flex-justify-content-between text-left" style="font-size: 22px;line-height: 55px;background: #F7F7F7;border-top: 1px solid #F0F0F0;">
                            <div style="width: 15%;padding-left: 20px;border-right: 1px solid #F0F0F0;">配件</div>
                            <div style="width: 40%;padding-left: 20px;border-right: 1px solid #F0F0F0;">商品</div>
                            <div style="width: 15%;padding-left: 20px;border-right: 1px solid #F0F0F0;">数量</div>
                            <div style="width: 15%;padding-left: 20px;border-right: 1px solid #F0F0F0;">单价</div>
                            <div style="width: 15%;padding-left: 20px;border-right: 1px solid #F0F0F0;">总价</div>
                        </div>
                        <div class="flex flex-justify-content-between text-left" style="font-size: 22px;line-height: 55px;border-top: 1px solid #F0F0F0;" v-for="(item, index) in printList" :key="index">
                            <div style="width: 15%;padding-left: 20px;border-right: 1px solid #F0F0F0;">{{ item.goods_type_name }}</div>
                            <div style="width: 40%;padding-left: 20px;border-right: 1px solid #F0F0F0;">{{ item.goods_name }}</div>
                            <div style="width: 15%;padding-left: 20px;border-right: 1px solid #F0F0F0;">x{{ item.goods_num }}</div>
                            <div style="width: 15%;padding-left: 20px;border-right: 1px solid #F0F0F0;">{{ item.price }}</div>
                            <div style="width: 15%;padding-left: 20px;">{{ (item.goods_num * item.price).toFixed(2) }}</div>
                        </div>
                        <div class="flex flex-justify-content-between" style="background: #F7F7F7;line-height: 55px;padding: 0 20px;
                       border-top: 1px solid #F0F0F0;border-right: 1px solid #F0F0F0;border-bottom: 1px solid #F0F0F0;">
                            <div style="font-size: 22px;">合计</div>
                            <div style="font-size: 22px;">{{ totalPrice }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import $ from 'jquery'
import html2canvas from 'html2canvas';

export default {
    name: "customize",
    data() {
        return {
            tableTitle: [
                {
                    name: '配件',
                    value: 'num',
                    width: 200
                },
                {
                    name: '商品',
                    value: 'goods_name',
                    width: 312
                },
                {
                    name: '数量',
                    value: 'goods_num',
                    width: 180
                },
                {
                    name: '单价',
                    value: 'price',
                    width: 200
                },
                {
                    name: '总价',
                    value: 'create_at',
                    // width: 200,
                    formatter: (row) => {
                        return row.goods_num * row.price;
                    }
                }
            ],
            accessoriesTypeList: [],
            shopList: [],
            templateList: [],
            template_id: '',
            remark: '',
            dialogFormVisible: false,
            dialogList: [],
            totalPrice: 0,
            name: '',
            url: '',
            printVisible: false,
            printObj: {
                id: "print",//要打印的id名 无#号
                popTitle: '&nbsp;',//页眉标题 默认浏览器标题 空字符串时显示undefined 使用html语言
                extraHead: '&nbsp;',//头部文字 默认空
            },
            printList: [],
            recharge_time: this.dateTypeFormat('YYYY-mm-dd', new Date()),
            loading: false,
            goods_list: [],
            goods_type_id: ''
        };
    },
    computed: {
    },
    watch: {
        '$store.state.turnOnOff': function (val) {
            if (val) {
                this.shopList.map((item, index) => {
                    if (item.goods_id) {
                        item.price = item.price_profit
                    }
                })
            } else {
                this.shopList.map((item, index) => {
                    if (item.goods_id) {
                        item.price = item.old_price
                    }
                })
            }
            this.computPrice()
        }
    },
    async mounted() {
        this.goods_list = []
        this.onList()
        this.recommend()
        this.$store.commit('settab', 1)
    },
    methods: {
        /**
         * 打印预览
         */
        printPreview() {
            let $printNone = $('.print-none').hide();
            let dialog = this.$loading('请稍后...');
            html2canvas(this.$refs.print, {
                allowTaint: true,
                useCORS: true
            }).then(canvas => {
                let dom = document.body.appendChild(canvas);
                dom.style.display = 'none';
                let a = document.createElement('a');
                a.style.display = 'none';
                document.body.removeChild(dom);
                let url = URL.createObjectURL(this.dataURLToBlob(dom.toDataURL('image/png')));
                console.log('this.previewImg', url)
                // 生成一个a元素
                var b = document.createElement("a");
                // 创建一个单击事件
                var event = new MouseEvent("click");
                let time = new Date()
                let timer = time.toLocaleString()
                let name = '装机配置单' + timer//下载的文件名  注意：加.xls是兼容火狐浏览器
                // 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
                b.download = name || "下载图片名称";
                // 将生成的URL设置为a.href属性
                b.href = url;

                // 触发a的单击事件
                b.dispatchEvent(event);
                dialog.close();
            });

        },
        /**
         * data转URLBlob
         * @param data
         * @returns {Blob}
         */
        dataURLToBlob(data) {
            let arr = data.split(',');
            let mime = arr[0].match(/:(.*?);/)[1];
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        },
        /**
         * 转换日期
         * */
        dateTypeFormat(fmt, date) {
            let ret
            const opt = {
                'Y+': date.getFullYear().toString(), // 年
                'm+': (date.getMonth() + 1).toString(), // 月
                'd+': date.getDate().toString(), // 日
                'H+': date.getHours().toString(), // 时
                'M+': date.getMinutes().toString(), // 分
                'S+': date.getSeconds().toString() // 秒
                // 有其他格式化字符需求可以继续添加，必须转化成字符串
            }
            for (const k in opt) {
                ret = new RegExp('(' + k + ')').exec(fmt)
                if (ret) {
                    fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
                }
            }
            return fmt
        },
        /**
         * 打印点击
         * */
        printShow() {
            let list = []
            this.shopList.map((item, index) => {
                if (item.goods_name !== '') {
                    list.push(item)
                }
            })
            if (list.length <= 0) {
                this.$message({ message: '请先选择要打印的商品！' });
                return;
            }
            this.printList = list
            console.log('this.printList', this.printList)
            this.printVisible = true
        },
        /**
         *  结算按钮点击
         * */
        jiesuan() {
            let list = []
            console.log('this.shopList', this.shopList)
            this.shopList.map((item, index) => {
                item.customize = 1
                if (item.goods_name !== '') {
                    list.push(item)
                }
            })
            if (list.length <= 0) {
                this.$message({ message: '请先选择要结算的商品！' });
                return;
            }
            this.$store.commit('setshop', list);
            this.$router.push('/ConfirmOrder');
        },
        /**
         *  输入框获取焦点
         * */
        goodfocus(item, index) {
            console.log(item, index)
        },
        goodfocusNum(item, index) {
            this.computPrice()
            console.log(item, index)
        },
        /**
         *  输入框失去焦点或回车
         * */
        goodInput(item, index) {
            console.log(item, index)
            this.shopList[index].goods_id = ''
        },
        goodInputNum(item, index) {
            // this.shopList[index].price = ''
            this.computPrice()
        },
        /**
         * 清空按钮点击
         * */
        qingkong() {
            this.name = ''
            this.totalPrice = 0
            this.remark = ''
            this.template_id = ''
            this.shopList.map((item, index) => {
                item.goods_num = 0
                item.price = 0,
                    item.goods_unit = ''
                item.goods_id = ''
                item.goods_name = ''
                item.goods_spec = ''
            })
        },
        deleteTemplate(data) {
            this.$confirm('确认要删除该模版？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post('api/kuerp/order/index/delete_template', {
                    template_id: data.id
                }).then(res => {
                    if (res.data.code == 200) {
                        this.my_template()
                        this.$message.success('删除成功!')
                    } else {
                        this.$message.error('删除失败!')
                    }
                })
            }).catch(() => {
                // this.$message({
                //     type    : 'info',
                //     message : '已取消删除'
                // });
            });

        },
        /**
         * 模板单选框
         * */
        radioInput(eve) {
            this.$axios.post('api/kuerp/order/index/template_info', {
                template_id: eve
            }).then(res => {
                this.shopList.map((item, index) => {
                    item.goods_num = 0
                    item.price = 0
                    item.goods_unit = ''
                    item.goods_id = ''
                    item.goods_name = ''
                    item.goods_image = ''
                    item.goods_spec = ''
                })
                this.shopList.map((item1, index1) => {
                    res.data.data.map((item, index) => {
                        if (item1.goods_type == item.goods_type) {
                            item1.goods_name = item.goods_name
                            item1.goods_id = item.goods_id
                            item1.goods_num = item.goods_num
                            item1.old_price = item.goods_price
                            item1.price_profit = item.price_profit
                            item1.price = this.$store.state.turnOnOff ? item.price_profit : item.goods_price
                            item1.goods_unit = item.goods_unit
                            item1.goods_image = item.goods_image
                            item1.goods_spec = item.goods_spec
                        }
                    })
                })
                this.computPrice()
            })
        },
        /**
         * 导出配置单
         * */
        derive() {
            let list = []
            this.shopList.map((item, index) => {
                if (item.goods_name !== '') {
                    list.push(item)
                }
            })
            if (list.length <= 0) {
                this.$message({ message: '请先选择要导出的商品！' });
                return;
            }
            axios({
                method: 'post',
                url: this.$axios.defaults.baseURL + '/api/kuerp/order/index/export_config_excel',
                responseType: 'blob',// 表明返回服务器返回的数据类型
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    goods_list: list,
                }
            }).then(res => {
                console.log('res', res)
                const link = document.createElement('a')
                let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                let time = new Date()
                let timer = time.toLocaleString()
                link.download = '装机配置单' + timer + '.xls'//下载的文件名  注意：加.xls是兼容火狐浏览器
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            // this.$axios.post(
            //     'api/kuerp/order/index/export_config_excel', {
            //     goods_list:list,
            //
            // }).then(res => {
            //
            // })
        },
        /**
         * 保存到模板
         * */
        submitTem() {
            if (this.name == '') {
                this.$message({ message: '请填写模板名字！' });
                return;
            }
            let list = []
            this.shopList.map((item, index) => {
                if (item.goods_name !== '') {
                    list.push(item)
                }
            })

            this.$axios.post('api/kuerp/order/index/createTemplate', {
                goods_list: list,
                name: this.name,
                remark: this.remark
            }).then(res => {
                this.my_template()
            })
        },
        /**
         * 推荐配置单
         * */
        recommend() {
            this.$axios.post('api/kuerp/order/index/template_list', {}).then(res => {
                this.templateList = res.data.data.list
            })
        },
        /**
         * 推荐配置单
         * */
        my_template() {
            this.$axios.post('api/kuerp/order/index/my_template_list', {}).then(res => {
                this.templateList = res.data.data.list
            })
        },
        deleteShop(item, index) {
            this.shopList[index].goods_name = ''
            this.shopList[index].goods_num = 0
            this.shopList[index].price = 0
            this.shopList[index].goods_id = ''
            this.shopList[index].goods_unit = ''
            this.shopList[index].goods_image = ''
            this.shopList[index].goods_spec = ''
            this.computPrice()
            this.template_id = ''
        },
        /**
        * 选择商品确定按钮
        * */
        selectGoods(goods, sindex) {
            this.dialogFormVisible = false
            this.shopList.map((item1, index1) => {
                item1.dialogList.map((item, pindex) => {
                    if (goods.goods_id == item.id) {
                        item1.goods_name = item.goods_name
                        item1.goods_id = item.id
                        item1.goods_num = 1
                        item1.old_price = item.price
                        item1.price_profit = item.price_profit
                        item1.price = this.$store.state.turnOnOff ? item.price_profit : item.price
                        item1.goods_unit = item.unit_id
                        item1.goods_image = item.goods_image
                        item1.goods_spec = item.goods_spec
                        // this.shopList[sindex] = {
                        //     goods_name: item.goods_name,
                        //     goods_id: item.id,
                        //     goods_num: 1,
                        //     old_price: item.price,
                        //     price_profit: item.price_profit,
                        //     price: this.$store.state.turnOnOff ? item.price_profit : item.price,
                        //     goods_unit: item.unit_id,
                        //     goods_image: item.goods_image,
                        //     goods_spec: item.goods_spec,
                        //     goods_type: item.goods_type_id,
                        //     dialogList: item1.dialogList
                        // }
                        // if (item1.goods_type == item.goods_type_id) {
                        // item1.goods_name = item.goods_name
                        // item1.goods_id = item.id
                        // item1.goods_num = 1
                        // item1.price = item.price
                        // item1.goods_unit = item.unit_id
                        // item1.goods_image = item.goods_image
                        // item1.goods_spec = item.goods_spec
                        // }
                    }
                })
            })
            this.computPrice()

        },
        /**
         * 选择商品确定按钮
         * */
        buttonSubmit() {
            this.dialogFormVisible = false
            this.dialogList.map((item, index) => {
                this.shopList.map((item1, index1) => {
                    if (item.id == this.template_id) {
                        if (item1.goods_type == item.goods_type_id) {
                            item1.goods_name = item.goods_name
                            item1.goods_id = item.id
                            item1.goods_num = 1
                            item1.old_price = item.price
                            item1.price_profit = item.price_profit
                            item1.price = this.$store.state.turnOnOff ? item.price_profit : item.price
                            item1.goods_unit = item.unit_id
                            item1.goods_image = item.goods_image
                            item1.goods_spec = item.goods_spec
                        }
                    }
                })
            })
            this.computPrice()

        },
        /**
         * 弹框关闭
         * */
        onDialogClose() {
            this.dialogFormVisible = false
            this.printVisible = false
        },
        /**
         * 打开弹框
         * */
        accessorClick(item) {
            console.log(item, 'item')
            this.dialogFormVisible = true
            this.template_id = ''
            this.dialogList = item.dialogList
            // this.$axios.post('api/kuerp/order/index/goodsGoodsList', {
            //     goods_type_id: item.id
            // }).then(res => {
            //     if (res.data.code == 200) {
            //         if (res.data.data.list.length > 0) {
            //             this.dialogList = res.data.data.list
            //         } else {
            //             this.dialogList = []
            //         }
            //     }

            // })
        },
        /**
         *  添加购物车
         * */
        btnAdd(value, index) {
            this.shopList[index].goods_num = value.goods_num + 1
            this.computPrice()
            // if(value.goods_name) {
            //     this.shopList.map((item,index) => {
            //         if(value.goods_id == item.goods_id) {
            //             item.goods_num = item.goods_num + 1
            //         }
            //     })
            //     let b = 0
            //     this.shopList.forEach(item=>{
            //         if(item.goods_name) {
            //             b = (item.goods_num*item.price)+b
            //         }
            //     })
            //     this.totalPrice = b.toFixed(2)
            // } else {
            //     this.$message({message : '请先选择商品！'});
            // }

        },
        /**
         *  减少购物车
         * */
        btnMinute(value, index) {
            // if(!value.goods_name) {
            //     this.$message({message : '请先选择商品！'});
            //     return;
            // }
            this.template_id = ''
            if (value.goods_num <= 1) {
                this.shopList[index].goods_name = ''
                this.shopList[index].goods_num = 0
                this.shopList[index].price = 0
                this.shopList[index].goods_id = ''
                this.shopList[index].goods_unit = ''
                this.shopList[index].goods_name = ''
                this.shopList[index].goods_image = ''
                this.shopList[index].goods_spec = ''
            } else {
                this.shopList[index].goods_num = value.goods_num - 1
                // this.shopList.map((item,index) => {
                //     if(value.goods_id == item.goods_id) {
                //         item.goods_num = item.goods_num - 1
                //     }
                // })
            }
            this.computPrice()
        },
        /**
         * 输入框输入
         * */
        number(eve, item) {
            // if(eve == '') {
            //     eve = 1
            // }
            // this.$axios.post('api/kuerp/order/index/editCart', this.$qs.stringify({
            //     goods_id:item.goods_id,
            //     unit_id:item.unit_id,
            //     number:eve
            // })).then(res => {
            //     if (res.data.code == 200) {
            //         // this.barList = res.data.data
            //         this.onList()
            //     }
            // })
        },
        /**
         * 配件接口
         * */
        onList() {
            this.$axios.post('api/kuerp/order/index/accessoriesTypeList', {}).then(res => {
                this.accessoriesTypeList = res.data.data

                for (let a = 0; a < this.accessoriesTypeList.length; a++) {
                    let b = {
                        goods_num: 0,
                        price: 0,
                        goods_type: this.accessoriesTypeList[a].id,
                        goods_type_name: this.accessoriesTypeList[a].name,
                        goods_unit: '',
                        goods_id: '',
                        goods_name: '',
                        goods_image: '',
                        goods_spec: '',
                        dialogList: []
                    }
                    this.shopList.push(b)
                }
                this.shopList.map((item, index) => {
                    this.$axios.post('api/kuerp/order/index/goodsNopageGoodsList', {
                        goods_type_id: item.goods_type,
                        keyword: ''
                    }).then(res1 => {
                        this.loading = false;
                        if (res1.data.code == 200 && res1.data.data.length > 0) {
                            item.dialogList = res1.data.data
                            this.accessoriesTypeList[index].dialogList = res1.data.data
                        }

                    })
                })
            })

        },
        computPrice() {
            let b = 0
            this.shopList.forEach(item => {
                if (item.goods_name) {
                    b = (item.goods_num * item.price) + b
                    item.total_price = (item.goods_num * item.price).toFixed(0)
                } else {
                    item.total_price = 0
                }
            })
            console.log(this.shopList, 'this.shopList')
            this.totalPrice = b.toFixed(2)
        }
    },
}
</script>
<style media="print">
@page {
    size: auto;
    margin: 25px;
}
</style>
<style scoped lang="scss">
.print-html {
    max-width: 1100px;
    margin: 0 auto;

    .print-none {
        display: none;
    }

    .edit-btn.active {
        display: block;
    }

    @media print {

        table td,
        table th {
            border: 1px #000000 solid;
        }
    }
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
    -moz-appearance: textfield !important;
}

.printBox {
    .dialog_con_print {
        height: 1000px;
    }

    ::v-deep {
        .el-table__row {
            font-size: 22px;
        }

        .el-table th.el-table__cell {
            background: #F7F7F7;
            font-size: 22px;
        }

        .el-dialog__body {
            max-height: 600px !important;
            overflow: hidden;
            overflow-y: scroll;
        }

        .el-dialog {
            //.el-radio {
            //    width: 48%!important;
            //}
        }
    }
}

::v-deep {

    //.el-table th.el-table__cell {
    //    background: #F7F7F7;
    //}

    .el-dialog__body {
        max-height: 6rem;
        overflow: hidden;
        overflow-y: scroll;
    }

    .el-dialog {
        //.el-radio {
        //    width: 48%!important;
        //}
    }

    .dialog-footer {
        text-align: center;
        padding: 0px !important;
    }

    .el-button.is-round {
        width: 2.8rem;
        height: 0.55rem;
        background: #4B83F0;
        border-radius: 0.04rem !important;
    }
}

.content-main-box {
    display: flex;
    margin-right: 0.1rem;

    .content-main-box-image {
        img {
            width: 0.7rem;
            height: 0.7rem;
            margin-right: 0.1rem;
        }
    }

    .content-main-box-text {
        width: 100%;
        font-size: 0.16rem;
        font-weight: 500;
        color: #393E4C;
        display: flex;
        align-items: center;
        margin-bottom: 0.1rem;
    }

    .content-main-box-spec {
        font-size: 0.12rem;
        font-weight: 400;
        color: #999999;
        line-height: 0.2rem;
        margin-bottom: 0.1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    .content-main-box-price {
        font-size: 0.18rem;
        font-weight: bold;
        color: #333333;

        span {
            display: block;
            width: 1rem;
        }
    }
}

::v-deep {
    .el-radio__label {
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 0.18rem;
        vertical-align: middle;
        display: inline-block;
    }

    .el-radio__input.is-checked+.el-radio__label {
        color: #393E4C;
    }

    .el-radio__input.is-checked .el-radio__inner::after {
        content: "";
        width: 0.15rem;
        height: 0.06rem;
        border: 0.02rem solid white;
        border-top: transparent;
        border-right: transparent;
        text-align: center;
        display: block;
        position: absolute;
        top: 0.05rem;
        left: 0.03rem;
        vertical-align: middle;
        transform: rotate(-45deg);
        border-radius: 0px;
        background: none;
    }

    .el-radio__inner {
        width: 0.24rem;
        height: 0.24rem;
    }

    .el-radio {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0.25rem;
    }

    .el-input__inner {
        font-weight: 400;
        font-size: 0.12rem;
        color: #999999;
    }

    .el-textarea__inner {
        border: none;
        font-weight: 400;
        height: 0.94rem;
        background: #FAFAFA;
        margin-bottom: 0.15rem;
        font-size: 0.12rem;
        color: #999999;
        line-height: 0.12rem;
        padding-top: 0.2rem;
    }
}

#index {
    width: 90%;
    height: 100vh;
    margin: 0.25rem auto;
    background: #434655;

}

.content_body {
    background: #434655;
}

.accessoriesBox {
    height: 0.56rem;
    background: #4B83F0;
    border-radius: 0.04rem;
    font-size: 0.16rem;
    font-weight: 500;
    color: #FFFFFF;
    padding: 0 0.23rem;
    margin-bottom: 0.05rem;
    margin-right: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accessoriesBoxBottom {
    height: 0.55rem;
    background: #fff;
    font-size: 0.16rem;
    font-weight: 700;
    color: #000;
    padding: 0 0.23rem;
    margin-bottom: 0.05rem;
    display: flex;
    align-items: center;
    justify-content: end;
}

.content_body_right {
    .button_box {
        width: 100%;
        font-size: 0.14rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.48rem;

        >div:nth-child(1),
        >div:nth-child(2),
        >div:nth-child(3) {
            width: 20%;
            height: 0.48rem;
            background: #4B83F0 linear-gradient(180deg, #444643 0%, #0C0C0C 100%);
            border-radius: 0.02rem;
            text-align: center;
            cursor: pointer;
        }

        >div:nth-child(2) {
            background: linear-gradient(180deg, #A5CEB5 0%, #47A66A 100%);
        }

        >div:nth-child(3) {
            background: #4B83F0 linear-gradient(180deg, #9D9D9D 0%, #7A7A7A 100%);
        }

        >div:nth-child(4) {
            width: 36%;
            height: 0.48rem;
            background: #4B83F0;
            border-radius: 0.02rem;
            text-align: center;
            cursor: pointer;
        }
    }

    .templateBox {
        width: 100%;
        height: 6.25rem;
        background: #FAFAFA;
        border-radius: 0.02rem;
        border: 0.01rem solid #E7E7E7;
        padding: 0.14rem 0.47rem 0.14rem 0.14rem;
        overflow: auto;
    }

    .priceBox {
        margin: 0.1rem 0;

        >div:nth-child(1) {
            color: #FFFFFF;
            font-size: 0.14rem;
            font-weight: 400;

            >span:nth-child(2) {
                font-size: 0.24rem;
                font-weight: normal;
                color: #FFFFFF;
                margin-left: 0.05rem;
            }
        }

        >div:nth-child(2) {
            width: 2.28rem;
            height: 0.48rem;
            background: #CF7AE2;
            border-radius: 0.02rem;
            font-size: 0.14rem;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 0.48rem;
            text-align: center;
            cursor: pointer;
        }
    }
}

.content_body_left {
    .delete {
        width: 0.48rem;
        height: 0.48rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #A8AABB;
        border-radius: 0.02rem;

        .shopDelete {
            width: 0.24rem;
            height: 0.24rem;
        }
    }

    .shop_box {
        height: 0.56rem;
        background: #FFFFFF;
        border-radius: 0.02rem;
        border: 0.01rem solid #CDCDCD;
        padding: 0 0.2rem;

        ::v-deep {
            .el-input__inner {
                height: 0.48rem;
                font-size: 0.16rem;
                font-weight: 500;
                color: #333333;
                border: none;
                line-height: 0.48rem;
            }
        }
    }

    .price_input {
        //margin-left: 1%;
        height: 0.56rem;
        display: flex;
        align-items: center;
        justify-content: center;

        ::v-deep {
            .el-input {
                width: 1rem;
            }

            .el-input__inner {
                width: 1rem;
                height: 0.48rem;
                font-size: 0.16rem;
                font-weight: 500;
                color: #333333;
                border: none;
                line-height: 0.48rem;
            }
        }
    }

    .num_input {
        height: 0.56rem;

        //margin-left: 1%;
        .btn_minute {
            //margin-left: 0.2rem;
        }

        .input {
            width: 1.4rem;
            height: 0.56rem;
            text-align: center;
            margin-left: 0.05rem;
            margin-right: 0.05rem;
            background: #FFFFFF;
            border-radius: 0.02rem;
            line-height: 0.56rem;
        }

        ::v-deep {
            .el-button {
                width: 0.56rem !important;
                height: 0.56rem !important;
                padding: 0 !important;
                font-size: 0.3rem;
            }
        }
    }
}

.content_top_box {
    background: #999999 linear-gradient(180deg, #444643 0%, #0C0C0C 100%);
    border-radius: 0.02rem;
    height: 0.48rem;
    font-size: 0.18rem;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 0.48rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bottom_text {
    font-size: 0.14rem;
    font-weight: 500;
    color: #999999;
    text-align: center;
    margin-top: 0.1rem;
}
</style>
